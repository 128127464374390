define("discourse/plugins/discourse-ratings/discourse/components/rating-star", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "input",
    disabled: (0, _computed.not)("enabled"),
    attributeBindings: ["value", "checked:checked", "disabled:disabled"],
    willInsertElement() {
      this._super(...arguments);
      this.element.type = "radio";
    },
    didRender() {
      this._super(...arguments);
      // For IE support
      this.element.value = this.value;
    },
    click() {
      this.set("rating", this.element.value);
    },
    checked(rating) {
      return this.get("value") <= rating;
    }
  }, [["method", "checked", [(0, _decorators.default)("rating")]]]));
});