define("discourse/plugins/discourse-ratings/discourse/components/rating-object-list", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-ratings/discourse/models/rating-object"], function (_exports, _component, _computed, _decorators, _I18n, _ratingObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":object-types", ":admin-ratings-list", "objectType"],
    hasObjects: (0, _computed.notEmpty)("objects"),
    title(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.title`);
    },
    nameLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.name`);
    },
    noneLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.none`);
    },
    actions: {
      newObject() {
        this.get("objects").pushObject({
          name: "",
          types: [],
          isNew: true
        });
      },
      addObject(obj) {
        let data = {
          name: obj.name,
          types: obj.types,
          type: this.objectType
        };
        this.set("loading", true);
        _ratingObject.default.add(data).then(result => {
          if (result.success) {
            this.refresh();
          } else {
            obj.set("hasError", true);
          }
          this.set("loading", false);
        });
      },
      updateObject(obj) {
        let data = {
          name: obj.name,
          types: obj.types
        };
        this.set("loading", true);
        _ratingObject.default.update(this.objectType, data).then(result => {
          if (result.success) {
            this.refresh();
          } else {
            obj.set("hasError", true);
          }
          this.set("loading", false);
        });
      },
      destroyObject(obj) {
        if (obj.isNew) {
          this.get("objects").removeObject(obj);
        } else {
          let data = {
            name: obj.name
          };
          this.set("loading", true);
          _ratingObject.default.destroy(this.objectType, data).then(result => {
            if (result.success) {
              this.refresh();
            } else {
              obj.set("hasError", true);
            }
            this.set("loading", false);
          });
        }
      }
    }
  }, [["method", "title", [(0, _decorators.default)("objectType")]], ["method", "nameLabel", [(0, _decorators.default)("objectType")]], ["method", "noneLabel", [(0, _decorators.default)("objectType")]]]));
});